import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from './layout'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        blogTitle
        baseUri
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMM YYYY")
            title
          }
        }
      }
    }
  }
`

const PostsPage = ({
  data: {
    site: { siteMetadata },
    allMarkdownRemark: { edges },
  },
  pageContext: { tags, categories }
}) => {
  const posts = edges
    .filter(edge => edge.node.frontmatter.date)
    .map(edge => edge.node)

  return <Layout>
    <Helmet title={`Archive - ${siteMetadata.blogTitle}`} />

    <header className="post-header">
      <h1 className="post-title">Archive</h1>
    </header>

    <section>
      <ul className="archive fancy-container darkishred fa-ul post">
        { posts.map(post =>
          <li key={post.fields.slug} className="mb-5">
            <i className="fa fa-li fa-circle-o fa-sm light-icon" />
            <Link to={post.fields.slug}>
              {post.frontmatter.title}
            </Link> <small className="text-muted pull-right">{post.frontmatter.date}</small>
          </li>
        ) }
      </ul>
    </section>

    <aside className="post">
      <header className="post-header">
        <h4 className="post-title">Tags</h4>
      </header>

      <ul className="pure-g post">
        { tags.map(tag =>
          <li key={tag.slug} className="pure-u-1-1 pure-u-lg-1-2">
            <i className="fa fa-fw fa-circle-o fa-sm light-icon mr-10" />
            <Link to={tag.url}>
              {tag.label}
            </Link> <small className="text-muted pull-right mr-10">{tag.count.toLocaleString()}</small>
          </li>
        ) }
      </ul>
    </aside>
  </Layout>
}

export default PostsPage
